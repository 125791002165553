import { Config } from './app-config.dto';

const getDefaults = (): Config => ({
  name: 'dev',
  production: false,
  logger: 'error',
  legacyApiServerUrl: 'apiUrl',
  legacyApiServerClientId: '',
  signupApiServerUrl: '/api/sign-me-up',
  mixpanel: '',
  ga: '',
  gtm: '',
  hubspot: {
    disabled: true,
    trackingCode: '',
  },
  stripe: '',
  clarity: '',
  socket: {
    apiKey: '',
    cluster: '',
    authEndpoint: '',
  },
  intercom: {
    disabled: true,
    appId: 'appId',
  },
  datadogRum: {
    applicationId: 'test_application_id',
    clientToken: 'test_client_token',
    env: 'test',
  },
  crm: {
    baseUrl: '/dougs-crm',
  },
  displayApi: {
    baseUrl: 'http://localhost:9400', // TODO correct config
  },
  sso: {
    clientId: 'fakeClientId',
  },
  signup: { disabled: true },
  enableInteractions: false,
  inpiGU: {
    baseUrl: '',
  },
  sentry: {
    dsn: '',
  },
});

export const getEnvironmentMock = (p?: Partial<Config>): Config => ({
  ...getDefaults(),
  ...p,
});
